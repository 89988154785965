import React, { useState } from "react";
import { RiPencilLine } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { Card, Button } from "antd";
import { TextField } from "@mui/material";
import useBiographyData from "../../hooks/useBiographyData";
import { IBiography, IBiographyData } from "../../types/typesConfig";

const Biography: React.FC<IBiography> = ({ idAdvisor }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { control, handleSubmit, reset } = useForm<IBiographyData>();
  const { biographyData, updateBiography } = useBiographyData(idAdvisor, reset);

  const onSubmit = async (data: IBiographyData) => {
    await updateBiography(data);
    setIsEditing(false);
  };

  return (
    <Card
      style={{
        width: "50%",
        height: "auto",
        backgroundColor: "#161617",
        border: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div>
          <p
            style={{
              color: "#E0E0E9",
              fontWeight: "500",
              marginTop: "-8px",
            }}
          >
            Informações Biográficas
          </p>
        </div>
        <div>
          {isEditing ? (
            <Button
              size="small"
              htmlType="submit"
              icon={
                <IoMdCheckmark
                  color="#000000"
                  size="12"
                  style={{ marginRight: -4 }}
                />
              }
              style={{
                color: "#000000",
                backgroundColor: "#FFC000",
                borderRadius: 20,
                fontSize: 10,
              }}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          ) : (
            <Button
              size="small"
              icon={
                <RiPencilLine
                  color="#ffc000"
                  size="12"
                  style={{ marginRight: -4 }}
                />
              }
              style={{
                color: "#FFC000",
                backgroundColor: "transparent",
                borderRadius: 20,
                fontSize: 10,
                border: "1px solid #FFC000",
              }}
              type="default"
              onClick={() => setIsEditing(true)}
            >
              Editar
            </Button>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="bio"
          control={control}
          defaultValue={biographyData.bio}
          render={({ field }) => (
            <TextField
              {...field}
              label="Biografia"
              multiline
              rows={18}
              variant="outlined"
              fullWidth
              disabled={!isEditing}
              style={{ backgroundColor: "#161617", color: "#fff" }}
            />
          )}
        />
      </form>
    </Card>
  );
};

export default Biography;
